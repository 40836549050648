<template>
	<div>
		<Navbar />
		<Header title="Developer" excerpt="Information regarding the developer related stuff">
			<HeaderButton title="New Token" excerpt="Create new developer token" />
		</Header>

		<!-- CONTENT -->
		<b-container>
			<b-card no-body style="border-color: #e1e6ee; border-width: 2px;">
				<b-card-body class="p-0">
					<b-table hover :items="tokens" :fields="fields">
					</b-table>
				</b-card-body>
			</b-card>
		</b-container>

	</div>
</template>

<script>
import Header from "@/components/Header";
import Navbar from "@/components/Navbar";
import HeaderButton from "@/components/Header/Button";

export default {
	name: "Developer",
	components: { Navbar, Header, HeaderButton },

	data: () => ({
		fields: [
			{
				key: "id",
				label: "ID",
				thClass: "text-center",
				tdClass: "text-center"
			},
			{
				key: "title",
				label: "Title"
			},
			{
				key: "token",
				label: "Token"
			}
		]
	}),

	computed: {
		tokens() { return this.$store.state.tokens.tokens }
	},

	mounted() {
		this.$store.dispatch("tokens/initialize")
	}

}
</script>

<style lang="scss" scope>
.credit-card {
	text-align: center;

	.title {
		font-size: 15px;
		font-weight: 600;
		color: $text-dark-secondary;
		letter-spacing: 3px;
	}

	h1 {
		margin-top: 50px;
		font-size: 35px !important;
		font-weight: 400 !important;
		color: $text-primary;
	}

	.appx {
		margin-top: 20px;
		font-weight: 600;
		font-size: 14px;
		color: $text-secondary;
	}
}
</style>
